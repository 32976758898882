import React, { useState } from 'react';
import { navigate } from 'gatsby';
import * as styles from './404.module.css';

//import Button from '../components/Button';
import Container from '../components/Container';
//import FormInputField from '../components/FormInputField/FormInputField';
import Layout from '../components/Layout';

const NotFoundPage = () => {
//  const [search, setSearch] = useState('');

  {/*const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${search}`);
  };
*/}
  return (
    <Layout disablePaddingBottom>
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>404 Error</h1>
          <h2>Page not found</h2>
          <p>
            Uh oh, looks like the page you are looking for has moved or no
            longer exists.
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
